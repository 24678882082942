import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { SignedInUser, NestedUser } from "@revelate/types";
import { getCalcUrl } from "@/lib/api";
import useSWR, { KeyedMutator } from "swr";

interface UseCommissionsStreamProps {
  currentUser: SignedInUser | null;
  dateRange?: DateRange;
  users?: NestedUser[];
  isProjected?: boolean;
  logResult?: boolean;
}

interface CommissionsStreamState {
  data: NestedUser[];
  error: string | null;
  isLoading: boolean;
  logs?: string[];
}

const initialState: CommissionsStreamState = {
  data: [],
  error: null,
  isLoading: false,
};

export function useCommissionsStream({
  currentUser,
  dateRange,
  users,
  isProjected = false,
  logResult = false,
}: UseCommissionsStreamProps) {
  const [streamState, setStreamState] =
    useState<CommissionsStreamState>(initialState);
  const url = getCalcUrl(currentUser, dateRange, users, isProjected, logResult);

  // Use SWR for caching the latest complete result
  const { data: cachedData, mutate } = useSWR(
    url,
    null, // We don't use the default fetcher
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: false,
    }
  ) as {
    data: NestedUser[];
    mutate: KeyedMutator<NestedUser[]>;
  };

  useEffect(() => {
    // If we have cached data and haven't explicitly requested a refresh, return early
    if (cachedData && !logResult) {
      setStreamState({
        data: cachedData,
        error: null,
        isLoading: false,
      });
      return;
    }

    if (!url) {
      setStreamState((prev) => ({
        ...prev,
        error: "Invalid URL parameters",
        isLoading: false,
      }));
      return;
    }

    let isSubscribed = true;
    const controller = new AbortController();
    const { signal } = controller;

    async function fetchStream() {
      if (!isSubscribed) return;
      setStreamState((prev) => ({ ...prev, isLoading: true, error: null }));

      try {
        const response = await fetch(`${import.meta.env.VITE_API_URL}/${url}`, {
          signal,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + import.meta.env.VITE_API_KEY,
          },
        });

        if (!response.ok) throw new Error(response.statusText);
        if (!response.body) throw new Error("No response body");

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = "";
        let isReading = true;
        let lastProgressTime = Date.now();

        // Start with cached data if available
        if (cachedData && isSubscribed) {
          setStreamState((prev) => ({
            ...prev,
            data: cachedData,
            isLoading: true,
          }));
        }

        while (isReading && isSubscribed) {
          const { done, value } = await reader.read();

          if (done) {
            isReading = false;
            if (buffer) {
              try {
                const result = JSON.parse(buffer);
                const newState = {
                  data: result.data || [],
                  logs: result.logs,
                  isLoading: false,
                  error: null,
                };
                setStreamState(newState);
                mutate(result.data, false);
                console.log('Stream completed, final data size:', 
                  Math.round(buffer.length / 1024), 'KB');
              } catch (e) {
                console.error("Error parsing final buffer:", e);
              }
            }
            break;
          }

          buffer += decoder.decode(value, { stream: true });
          
          // Log progress every 5 seconds
          const now = Date.now();
          if (now - lastProgressTime > 5000) {
            console.log('Stream progress:', Math.round(buffer.length / 1024), 'KB received');
            lastProgressTime = now;
          }

          // Try to parse complete JSON objects as they arrive
          try {
            const result = JSON.parse(buffer);
            if (result.data) {
              const newState = {
                data: result.data,
                logs: result.logs,
                isLoading: false,
                error: null,
              };
              setStreamState(newState);
              mutate(result.data, false);
              
              // Log completion metrics
              console.log('Stream completed successfully:', {
                dataSizeKB: Math.round(buffer.length / 1024),
                users: result.data.length,
                timeMs: Date.now() - lastProgressTime
              });
              
              buffer = "";
              isReading = false;
              break;
            }
          } catch (e) {
            // If buffer is getting too large, log a warning
            if (buffer.length > 50 * 1024 * 1024) { // 50MB
              console.warn('Large buffer size:', Math.round(buffer.length / 1024 / 1024), 'MB');
            }
            continue;
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          // Don't set error state for aborted requests
          if (error.name === "AbortError" || !isSubscribed) {
            return;
          }
          setStreamState((prev) => ({
            ...prev,
            error: error.message,
            isLoading: false,
          }));
        }
      }
    }

    fetchStream();

    return () => {
      isSubscribed = false;
      controller.abort();
    };
  }, [url, cachedData, logResult, mutate]); // Only re-run if url changes

  return {
    ...streamState,
    data: streamState.data.length > 0 ? streamState.data : cachedData || [],
  };
}
