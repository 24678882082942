import { ChevronRight, FileSpreadsheet } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { saveAs } from "file-saver";
import { useAppStore } from "@/stores";
import {
  getApproved,
  getBadgeVariant,
  getCurrencyFormatted,
  getPaid,
  getUsersList,
  getTimePeriodFromDateRange,
  getUnapproved,
  updateCommissionsStatus,
  getBadgeSummary,
  getUsersWithCommissions,
} from "@/lib/app";
import { useLocation, useParams } from "wouter";
import { ReportCard } from "./components/card";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { getCommissionsValue, getUsersForScope } from "@revelate/calc";
import { exportToExcel } from "@revelate/export";

import useSWR, { KeyedMutator } from "swr";
import { getRoute } from "@/lib/routes";
import { getFullName, getUserInitials } from "@revelate/utils";
import { TableType, get } from "@/lib/supabase";
import {
  CommissionStatus,
  NestedCommission,
  SignedInCompany,
} from "@revelate/types";
import { LoadingError } from "@/components/LoadingError";
import { useJune } from "@/hooks/useJune";
import { getExchangeRateToCurrency } from "@revelate/calc/currencies";
import { useState } from "react";
import { Scope } from "@/dashboard/components/scope";
import { ApproveButton } from "./components/ApproveButton";
import { getAPI, getCalcUrl } from "@/lib/api";
import { Loader } from "@/components/Loader";
import { useCommissions } from "@/providers/CommissionsProvider";

export function ReportsPage() {
  const appState = useAppStore((state) => state);
  const { scope, dateRange, currentUser } = appState;
  const {
    data: company,
    error: companyError,
    isLoading: companyIsLoading,
  } = useSWR(
    [
      "companies" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]): Promise<SignedInCompany> => get(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const analytics = useJune();
  const { id } = useParams();

  const [isUpdatingCommissions, setIsUpdatingCommissions] =
    useState<boolean>(false);

  const scopedUsers = company ? getUsersForScope(company, scope) : [];

  const { users, error, isLoading: commissionsLoading } = useCommissions();

  const scopedUserIds = scopedUsers.map((u) => u.id);

  const commissions =
    users
      ?.filter((user) => scopedUserIds.includes(user.id))
      ?.flatMap((user) => user.commissions) || [];

  const updateCommissions = async (
    previousStatus: CommissionStatus,
    newStatus: CommissionStatus,
    filterByCommission?: NestedCommission
  ) => {
    setIsUpdatingCommissions(true);
    await updateCommissionsStatus(
      commissions,
      previousStatus,
      newStatus,
      filterByCommission
    );
    setIsUpdatingCommissions(false);
  };

  const [, setLocation] = useLocation();

  const isLoading = companyIsLoading || commissionsLoading;

  if (isLoading) return <Loader />;
  if (companyError || error) return <LoadingError />;

  return (
    <main className="grid flex-1 items-start gap-4 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-5">
      <div className="auto-rows-max items-start gap-4 md:gap-8 lg:col-span-3">
        <div className="flex items-center justify-between space-y-2 pb-6">
          {/* <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2> */}

          <Scope />
          <ApproveButton
            commissions={commissions}
            updateCommissions={updateCommissions}
            scopedUsers={scopedUsers}
            loading={isUpdatingCommissions || isLoading}
          />
        </div>
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-6">
          <Card className="sm:col-span-2" x-chunk="dashboard-05-chunk-2">
            <CardHeader className="pb-2">
              <CardDescription>Total this period</CardDescription>

              <CardTitle className="text-4xl">
                {getCurrencyFormatted(
                  getCommissionsValue(getUnapproved(commissions)) +
                    getCommissionsValue(getApproved(commissions)) +
                    getCommissionsValue(getPaid(commissions))
                )}
              </CardTitle>
            </CardHeader>
            {/* <CardContent>
              <div className="text-xs text-muted-foreground">
                +10% from last year
              </div>
            </CardContent> */}
            {/* <CardFooter>
              <Progress value={12} aria-label="12% increase" />
            </CardFooter> */}
          </Card>
          <Card className="sm:col-span-2" x-chunk="dashboard-05-chunk-2">
            <CardHeader className="pb-2">
              <CardDescription>Approved this period</CardDescription>

              <CardTitle className="text-4xl">
                {getCurrencyFormatted(
                  getCommissionsValue(getApproved(commissions)) +
                    getCommissionsValue(getPaid(commissions))
                )}
              </CardTitle>
            </CardHeader>
            <CardContent>
              {/* <div className="text-xs text-muted-foreground">
                +25% from last week
              </div> */}
            </CardContent>
            {/* <CardContent>
              <div className="text-xs text-muted-foreground">
                +10% from last year
              </div>
            </CardContent> */}
            {/* <CardFooter>
              <Progress value={12} aria-label="12% increase" />
            </CardFooter> */}
          </Card>

          <Card className="sm:col-span-2" x-chunk="dashboard-05-chunk-1">
            <CardHeader className="pb-2">
              <CardDescription>Paid this period</CardDescription>

              <CardTitle className="text-4xl">
                {getCurrencyFormatted(
                  getCommissionsValue(getPaid(commissions))
                )}
              </CardTitle>
            </CardHeader>
            <CardContent>
              {/* <div className="text-xs text-muted-foreground">
                +25% from last week
              </div> */}
            </CardContent>
            {/* <CardFooter>
              <Progress value={25} aria-label="25% increase" />
            </CardFooter> */}
          </Card>
        </div>
        <div className="mt-4">
          <Tabs defaultValue="detailed">
            <div className="flex items-center">
              {/* <TabsList>
              <TabsTrigger value="detailed">Detailed report</TabsTrigger>
              <TabsTrigger value="summary">Summary</TabsTrigger>
            </TabsList> */}
              <div className="ml-auto flex items-center gap-2">
                {/* <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      className="h-7 gap-1 text-sm"
                    >
                      <ListFilter className="h-3.5 w-3.5" />
                      <span className="sr-only sm:not-sr-only">Filter</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel>Filter by</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuCheckboxItem>Unapproved</DropdownMenuCheckboxItem>
                    <DropdownMenuCheckboxItem>
                      Approved
                    </DropdownMenuCheckboxItem>
                    <DropdownMenuCheckboxItem>Paid</DropdownMenuCheckboxItem>
                  </DropdownMenuContent>
                </DropdownMenu> */}
                {currentUser?.role !== "employee" && (
                  <Button
                    size="sm"
                    variant="outline"
                    loading={isLoading}
                    className="h-7 gap-1 text-sm"
                    onClick={async () => {
                      const fileName = `${
                        company?.name
                      } - ${getTimePeriodFromDateRange(dateRange)}`;

                      const buffer = await exportToExcel({
                        users: users || [],
                      });

                      if (buffer)
                        saveAs(new Blob([buffer]), `${fileName}.xlsx`);

                      analytics?.track("Report exported", {
                        format: "xlsx",
                        company: company?.name,
                        timePeriod: getTimePeriodFromDateRange(dateRange),
                      });
                    }}
                  >
                    <FileSpreadsheet className="h-3.5 w-3.5" />
                    <span className="sr-only sm:not-sr-only">
                      Export to Excel
                    </span>
                  </Button>
                )}
              </div>
            </div>
            <TabsContent value="detailed">
              <Card x-chunk="dashboard-05-chunk-3">
                <CardHeader className="px-7">
                  <CardTitle>{getTimePeriodFromDateRange(dateRange)}</CardTitle>
                  <CardDescription>
                    {users?.length || 0} employees have earned commissions in
                    the time period.
                  </CardDescription>
                </CardHeader>

                <CardContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead></TableHead>
                        <TableHead>Employee</TableHead>

                        <TableHead className="text-right hidden sm:table-cell">
                          To approve
                        </TableHead>
                        <TableHead className="text-right hidden xl:table-cell">
                          To pay
                        </TableHead>
                        <TableHead className="text-right hidden xl:table-cell">
                          Paid
                        </TableHead>

                        <TableHead></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {users && getUsersWithCommissions(users)?.map((user, index) => {
                        // const userCommissions = consolidateCommissions(
                        //   user.commissions,
                        //   persistedCommissions
                        // );
                        return (
                          <TableRow
                            className={
                              user.id === scope?.value ? "bg-accent" : ""
                            }
                            key={index}
                          >
                            <TableCell>
                              <Avatar className="h-9 w-9">
                                <AvatarImage
                                  src={user.avatar_url || ""}
                                  alt="Avatar"
                                />
                                <AvatarFallback>
                                  {getUserInitials(user)}
                                </AvatarFallback>
                              </Avatar>
                            </TableCell>
                            <TableCell>
                              <div className="font-medium">
                                {getFullName(user)}{" "}
                                <Badge
                                  className="text-xs"
                                  variant={
                                    getCommissionsValue(user.commissions) > 0
                                      ? getBadgeVariant(user.commissions)
                                      : "secondary"
                                  }
                                >
                                  {getBadgeSummary(user.commissions)}
                                </Badge>
                              </div>
                              <div className="hidden text-sm text-muted-foreground md:inline">
                                {user.email}
                              </div>
                            </TableCell>

                            <TableCell className="text-right hidden sm:table-cell">
                              <div className="font-medium">
                                {getCurrencyFormatted(
                                  getCommissionsValue(
                                    getUnapproved(user.commissions)
                                  )
                                )}
                              </div>
                              {user?.currency &&
                                company?.default_currency_id !==
                                  user?.currency?.id && (
                                  <div className="hidden text-sm text-muted-foreground md:inline">
                                    {getCurrencyFormatted(
                                      getCommissionsValue(
                                        getUnapproved(user.commissions)
                                      ) /
                                        getExchangeRateToCurrency(
                                          user.currency,
                                          company
                                        ),
                                      user.currency?.code
                                    )}
                                  </div>
                                )}
                            </TableCell>

                            <TableCell className="text-right hidden xl:table-cell">
                              <div className="font-medium">
                                {getCurrencyFormatted(
                                  getCommissionsValue(
                                    getApproved(user.commissions)
                                  )
                                )}
                              </div>
                              {user?.currency &&
                                company?.default_currency_id !==
                                  user?.currency?.id && (
                                  <div className="hidden text-sm text-muted-foreground md:inline">
                                    {getCurrencyFormatted(
                                      getCommissionsValue(
                                        getApproved(user.commissions)
                                      ) /
                                        getExchangeRateToCurrency(
                                          user.currency,
                                          company
                                        ),
                                      user.currency?.code
                                    )}
                                  </div>
                                )}
                            </TableCell>
                            <TableCell className="text-right hidden xl:table-cell">
                              <div className="font-medium">
                                {getCurrencyFormatted(
                                  getCommissionsValue(getPaid(user.commissions))
                                )}
                              </div>
                              {user?.currency &&
                                company?.default_currency_id !==
                                  user?.currency?.id && (
                                  <div className="hidden text-sm text-muted-foreground md:inline">
                                    {getCurrencyFormatted(
                                      getCommissionsValue(
                                        getPaid(user.commissions)
                                      ) /
                                        getExchangeRateToCurrency(
                                          user.currency,
                                          company
                                        ),
                                      user.currency?.code
                                    )}
                                  </div>
                                )}
                            </TableCell>

                            <TableCell>
                              <div className="ml-2 font-medium flex gap-1">
                                <Button
                                  // disabled={
                                  //   getCommissionsValue(user.commissions) === 0
                                  // }
                                  onClick={() =>
                                    setLocation(
                                      getRoute("reports", "user", user.id)
                                    )
                                  }
                                  variant={
                                    user.id === scope?.value
                                      ? "outline"
                                      : "outline"
                                  }
                                  size="icon"
                                >
                                  <ChevronRight className="h-4 w-4" />
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
      </div>
      <div className="md:col-span-1 lg:col-span-2">
        <ReportCard
          employees={users && users.length > 0 ? getUsersList(users) : []}
          timePeriod={getTimePeriodFromDateRange(dateRange)}
          ids={
            id
              ? [id.toString()]
              : scopedUsers && scopedUsers.length > 0
                ? scopedUsers?.map((u) => u.id)
                : []
          }
          commissions={commissions}
          updateCommissions={updateCommissions}
          isUpdatingCommissions={isUpdatingCommissions}
          scopedUsers={scopedUsers}
          dateRange={dateRange}
        />
      </div>
    </main>
  );
}
