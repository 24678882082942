import { TODO } from "@revelate/types";
import { supabaseClient } from "./client";

export type TableType =
  | "companies"
  | "plans"
  | "quotas"
  | "currencies"
  | "exchange_rates"
  | "deals"
  | "tokens"
  | "users"
  | "teams";

export const getTableColums = (table: TableType) => {
  switch (table) {
    case "companies":
      return `*, teams(*, users(*)), plans(*), quotas(*), users!public_profiles_company_id_fkey(*), default_currency:currencies!companies_default_currency_id_fkey(*, exchange_rates!exchange_rates_to_currency_id_fkey(*))`;
    case "plans":
      return "*, teams(*, users(*)), users(*), accelerators(*, conditions(*)))";
    case "quotas":
      return "*, currency:currencies(*), users(*), goals(*, users(*))";
    case "currencies":
      return "*, exchange_rates!exchange_rates_from_currency_id_fkey(*)";
    case "tokens":
      return "id, provider, created_at, updated_at, is_expired, allow_update";
    case "exchange_rates":
      return "*";
    case "deals":
      return "*, users:user_deals(user_id, role, data:users(*)))";
    case "users":
      return "*, currency:currencies!users_currency_id_fkey(*), deals!user_deals(id), plans!user_plans(id)";
    case "teams":
      return "*, users(*)";
    default:
      return "*";
  }
};

export const getAll = async (
  table: TableType,
  companyId: string | null,
  activeOnly = true,
  userRole?: string
): Promise<TODO[]> => {
  const query = supabaseClient
    .from(table)
    .select(getTableColums(table))
    .eq("company_id", Number(companyId));

  if (table === "users") {
    query.neq("role", "super_admin");
    if (activeOnly) {
      query.eq("is_active", true);
    }
  }

  if (table === "quotas" && userRole !== "super_admin") {
    query.eq("is_hidden", false);
  }

  if (table === "plans") {
    query.order("name", { ascending: true });
  }

  if (table === "teams") {
    query.order("name", { ascending: true });
  }

  const { data, error } = await query.returns<TODO[]>();
  if (error) {
    throw error;
  }
  return data;
};

export const get = async (
  table: TableType,
  id?: string | null,
  activeOnly = true
): Promise<TODO | null> => {
  if (!id) {
    return null;
  }
  const query = supabaseClient
    .from(table)
    .select(getTableColums(table))
    .eq("id", Number(id));

  if (table === "companies") {
    query.neq("users.role", "super_admin");
    if (activeOnly) {
      query.eq("users.is_active", true);
    }
  }

  const { data, error } = await query.returns<TODO[]>().maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};
