import {
  NestedUser,
  NestedPlan,
  DateRange,
  NestedDeal,
  GroupedCommissions,
  KeyDeal,
  NestedTeam,
  CommissionBasedOn,
  TimePeriod,
  SignedInCompany,
} from "@revelate/types";
import { criteriaMet } from "./accelerators";
import {
  CommissionStatus,
  CommissionUser,
  NestedCommission,
  NestedCompany,
  Scope,
  TODO,
} from "@revelate/types";
import {
  format,
  parse,
  isWithinInterval,
  startOfDay,
  endOfDay,
} from "date-fns";
import { enUS } from "date-fns/locale";
import { getUserIdsForTeam } from "./teams";
import {
  filterByDealRole,
  getDealsForDateRange,
  getDealsValue,
  getDealValueFromCommissions,
} from "./deals";
import {
  getStartEndDates,
  getStartOfYearToEndOfPreviousTimePeriod,
  shouldCalculate,
} from "@revelate/utils";
import {
  getExchangeRateForCurrencyId,
  getExchangeRateForDeal,
} from "./currencies";
import { OperationTimer, logOperation } from './performance';

const getCommissionBasedOn = (
  deal: NestedDeal,
  commission_based_on: string,
  isProjected: boolean = false
) => {
  let value = 0;

  switch (commission_based_on) {
    case "deal_value":
      value = deal.value || 0;
      break;
    case "onboarding_value":
      value = deal.onboarding_value ? deal.onboarding_value : 0;
      break;
    case "deal_value_arr":
      value = deal.value * 12 || 0;
      break;
    case "deal_value_above_target":
      console.log(
        "ERROR: Connect this accelerator to a quota to use deal_value_above_target"
      );
      return 0;
    case "deal_value_below_target":
      console.log(
        "ERROR: Connect this accelerator to a quota to use deal_value_below_target"
      );
      return 0;
    default:
      value = deal.value || 0;
  }

  const projectionMultiplier = isProjected ? deal.likelihood_to_close || 1 : 1;
  return value * projectionMultiplier;
};

const getCommissionBasis = (
  company: NestedCompany,
  deal: NestedDeal,
  commission_based_on?: string,
  isProjected: boolean = false
) => {
  const value = getCommissionBasedOn(
    deal,
    commission_based_on || "",
    isProjected
  );
  return value * getExchangeRateForDeal(company, deal);
};

const getAboveBelowCommissionBasis = (
  commission_based_on: CommissionBasedOn,
  targetValue: number,
  totalDealValue: number,
  previousTotalDealValue: number,
  isYTD: boolean
) => {
  if (commission_based_on === "deal_value_above_target") {
    const aboveTargetDealValue = isYTD
      ? totalDealValue + previousTotalDealValue
      : totalDealValue;
    const remainingDealValue = isYTD
      ? totalDealValue -
        (targetValue > previousTotalDealValue
          ? targetValue - previousTotalDealValue
          : 0)
      : totalDealValue - targetValue;
    return aboveTargetDealValue > targetValue ? remainingDealValue : 0;
  }
  if (commission_based_on === "deal_value_below_target") {
    const belowTargetValueRemaining = isYTD
      ? targetValue - previousTotalDealValue
      : targetValue;
    if (belowTargetValueRemaining <= 0) return 0;
    return totalDealValue > belowTargetValueRemaining
      ? belowTargetValueRemaining
      : totalDealValue;
  }
  return 0;
};

function getProviderStats(deals: NestedDeal[]) {
  return deals.reduce((acc, deal) => {
    const provider = deal.provider || 'unknown';
    if (!acc[provider]) {
      acc[provider] = { count: 0, totalValue: 0 };
    }
    acc[provider].count++;
    acc[provider].totalValue += deal.value || 0;
    return acc;
  }, {} as Record<string, { count: number; totalValue: number }>);
}

function precomputeProviderExchangeRates(company: NestedCompany, deals: NestedDeal[]) {
  const rateCache = new Map<string, number>();
  
  deals.forEach(deal => {
    const key = `${deal.provider}:${deal.currency_id || company.default_currency_id}`;
    if (!rateCache.has(key)) {
      rateCache.set(key, getExchangeRateForDeal(company, deal));
    }
  });
  
  return rateCache;
}

export const calculateCommissionsForPlan = async ({
  calculationDate,
  dateRange,
  company,
  user,
  plan,
  isProjected = false,
  logs,
}: {
  calculationDate: Date;
  dateRange: DateRange;
  company: NestedCompany;
  user: NestedUser;
  plan: NestedPlan;
  isProjected: boolean;
  logs: string[];
}) => {
  const planTimer = new OperationTimer('calculateCommissionsForPlan', { 
    userId: user.id, 
    planId: plan?.id,
    providers: plan?.providers || [],
    dealTypes: plan?.deal_types || []
  });

  if (!user || !plan || !dateRange) return null;

  const { time_period, deal_types, providers, accelerators, deal_role } =
    plan || {};

  // Check if we should use this plan for the given date
  if (!shouldCalculate(calculationDate, time_period) || !time_period)
    return null;

  const dealsTimer = new OperationTimer('deals-filtering', { 
    userId: user.id, 
    dealRole: deal_role 
  });
  
  // Get all deals for this user's role
  const deals = filterByDealRole(company.deals || [], deal_role, user.id);
  
  // Filter the deals for the given time period once
  const dealsForDateRange: NestedDeal[] = getDealsForDateRange({
    deals,
    dateRange: getStartEndDates(dateRange, calculationDate, time_period),
    dealTypes: deal_types,
    providers: providers || undefined,
    dealRole: deal_role,
    userId: user.id,
    isProjected,
  });

  // Calculate provider stats once
  const providerStats = getProviderStats(deals);
  const filteredProviderStats = getProviderStats(dealsForDateRange);

  logOperation(dealsTimer.end(), logs);

  const commissions: NestedCommission[] = [];
  const dealsPassed: NestedDeal[] = [];

  // User can now have multiple accelerators per plan that kick in
  // So we need to loop through all of them and check the conditions for each one
  if (!accelerators || accelerators.length === 0) return null;

  // Order accelerators by highest rate
  const sortedAccelerators = accelerators.sort((a, b) => b.rate - a.rate);

  const acceleratorsTimer = new OperationTimer('accelerators-processing', {
    userId: user.id,
    numAccelerators: sortedAccelerators.length,
    totalDeals: dealsForDateRange.length,
    providerStats: filteredProviderStats
  });

  // Precompute exchange rates for all deals once
  const exchangeRates = precomputeProviderExchangeRates(company, dealsForDateRange);

  // Cache deal values for each deal to avoid recalculating
  const dealValueCache = new Map<string, number>();
  dealsForDateRange.forEach(deal => {
    const value = getCommissionBasedOn(deal, "", isProjected);
    const key = `${deal.provider}:${deal.currency_id || company.default_currency_id}`;
    const rate = exchangeRates.get(key) || getExchangeRateForDeal(company, deal);
    dealValueCache.set(deal.id.toString(), value * rate);
  });

  // Helper function to get cached deal value
  const getOptimizedCommissionBasis = (
    deal: NestedDeal,
    commission_based_on?: string
  ) => {
    if (!commission_based_on || commission_based_on === "") {
      return dealValueCache.get(deal.id.toString()) || 0;
    }
    // For other commission_based_on types, calculate normally
    const value = getCommissionBasedOn(deal, commission_based_on, isProjected);
    const key = `${deal.provider}:${deal.currency_id || company.default_currency_id}`;
    const rate = exchangeRates.get(key) || getExchangeRateForDeal(company, deal);
    return value * rate;
  };

  for (const accelerator of sortedAccelerators) {
    const acceleratorTimer = new OperationTimer('accelerator-check', {
      userId: user.id,
      acceleratorId: accelerator.id,
      acceleratorName: accelerator.name,
      acceleratorRate: accelerator.rate,
      commission_based_on: accelerator.commission_based_on,
      conditions: accelerator.conditions?.length || 0
    });

    const {
      commission_based_on,
      fixed_value_amount,
      fixed_value_currency_id,
      rate,
    } = accelerator;

    // Check if we meet the criteria for the accelerator, and filter the deals
    const {
      success,
      successQuotaTargetValue,
      successQuotaIsYTD,
      filteredDeals,
    } = await criteriaMet({
      calculationDate,
      dateRange,
      company,
      user,
      deals,
      plan,
      accelerator,
      dealsForDateRange,
      logs,
    });

    // Add provider stats for filtered deals if we have a match
    if (success && filteredDeals) {
      acceleratorTimer.setMetadata({
        dealsMatched: filteredDeals.length,
        providerStats: getProviderStats(filteredDeals),
        quotaTarget: successQuotaTargetValue,
        isYTD: successQuotaIsYTD
      });
    }

    logOperation(acceleratorTimer.end(), logs);

    if (!success) {
      continue;
    }

    const calculationTimer = new OperationTimer('commission-calculation', {
      userId: user.id,
      acceleratorId: accelerator.id,
      numDeals: filteredDeals.length
    });

    const baseCommission: NestedCommission = {
      company_id: company.id,
      month: Number(format(calculationDate, "MM")),
      year: Number(format(calculationDate, "yyyy")),
      user_id: user.id,
      plan_id: plan.id,
      accelerator: accelerator,
      accelerator_id: accelerator.id,
      rate,
      plan,
      created_at: new Date() as TODO,
      updated_at: new Date() as TODO,
      status: isProjected ? "projected" : "unapproved",
      deal_id: null,
      deal: undefined,
      commission_basis: 0,
      amount: 0,
    };

    // Get deal value using cached values
    const totalDealValue = filteredDeals.reduce((sum, deal) => sum + (dealValueCache.get(deal.id.toString()) || 0), 0);

    if (commission_based_on === "fixed_value" && fixed_value_amount) {
      // const deal = filteredDeals && filteredDeals.length === 1 ? filteredDeals[0] : null;
      const commission_basis = fixed_value_currency_id
        ? getExchangeRateForCurrencyId(company, fixed_value_currency_id) *
          fixed_value_amount
        : fixed_value_amount;
      const amount = commission_basis * rate;
      const commission: NestedCommission = {
        ...baseCommission,
        commission_basis,
        amount,
        value_actual: totalDealValue,
        value_target: successQuotaTargetValue,
        deals_count: filteredDeals?.length || 0,
        // deal_id: deal ? deal.id : null,
        // deal: deal || undefined,
      };
      if (amount > 0) {
        commissions.push(commission);
      }
    } else if (
      (commission_based_on === "deal_value_above_target" ||
        commission_based_on === "deal_value_below_target") &&
      successQuotaTargetValue
    ) {
      const dealsForPreviousDateRange: NestedDeal[] = getDealsForDateRange({
        deals,
        dateRange: getStartOfYearToEndOfPreviousTimePeriod(
          calculationDate,
          time_period
        ),
        dealTypes: deal_types,
        providers: providers || undefined,
        dealRole: deal_role,
        userId: user.id,
        isProjected,
      });

      const previousTotalDealValue = dealsForPreviousDateRange
        ?.map((deal) => getExchangeRateForDeal(company, deal) * deal.value)
        .reduce((acc, value) => acc + value, 0);

      const targetValue = successQuotaTargetValue;

      const commission_basis = getAboveBelowCommissionBasis(
        commission_based_on,
        targetValue,
        totalDealValue,
        previousTotalDealValue,
        !!successQuotaIsYTD
      );

      const amount = commission_basis * rate;
      const commission: NestedCommission = {
        ...baseCommission,
        commission_basis,
        amount,
        value_actual: totalDealValue,
        value_target: successQuotaTargetValue,
        deals_count: filteredDeals?.length || 0,
        // deal_id: deal ? deal.id : null,
        // deal: deal || undefined,
      };

      if (amount > 0) {
        commissions.push(commission);
        logs.push(
          `Accelerator matched: ${accelerator.name} (Accelerator ID: ${accelerator.id})`,
          `Commission based on: ${commission_based_on}`,
          `Deals passed: ${filteredDeals.length} (${filteredDeals.map((deal) => `${deal.name}: ${getExchangeRateForDeal(company, deal) * deal.value}`).join(", ")})`,
          `YTD: ${successQuotaIsYTD}`,
          `Actual value previous period: ${previousTotalDealValue}`,
          `Actual value this period: ${totalDealValue}`,
          `Target value: ${successQuotaTargetValue}`,
          `Commission basis: ${commission_basis}`,
          `Rate: ${accelerator.rate}`,
          `Amount to be paid: ${amount}`,
          "\n"
        );
      }
    } else {
      // Process deals in batches for better memory management
      const processDealBatch = async (deals: NestedDeal[]) => {
        const batchSize = 100;
        const results: NestedCommission[] = [];
        
        for (let i = 0; i < deals.length; i += batchSize) {
          const batch = deals.slice(i, i + batchSize);
          const batchResults = await Promise.all(batch.map(async (deal) => {
            const commission_basis = getOptimizedCommissionBasis(
              deal,
              commission_based_on
            );
            const amount = commission_basis * rate;
            return {
              ...baseCommission,
              deal_id: deal.id,
              deal: deal,
              commission_basis,
              amount,
            };
          }));
          
          results.push(...batchResults);
          dealsPassed.push(...batch);
        }
        
        return results;
      };

      const results = await processDealBatch(filteredDeals);
      commissions.push(...results);
      
      logs.push(
        `Accelerator matched: ${accelerator.name} (Accelerator ID: ${accelerator.id})`,
        `Commission based on: ${commission_based_on} ${
          successQuotaTargetValue
            ? `(Actual value this period: ${totalDealValue}, Target value: ${successQuotaTargetValue})`
            : ""
        }`,
        `Deals passed: ${filteredDeals.length} (${filteredDeals.map((deal) => `${deal.name}: ${getExchangeRateForDeal(company, deal) * deal.value}`).join(", ")})`,
        `Commission basis: ${commissions
          .reduce((acc, commission) => acc + commission.commission_basis, 0)
          .toString()}`,
        `Rate: ${accelerator.rate}`,
        `Amount to be paid: ${commissions.reduce((acc, commission) => acc + commission.amount, 0)}`,
        "\n"
      );
    }

    logOperation(calculationTimer.end(), logs);
  }

  logOperation(acceleratorsTimer.end(), logs);
  logOperation(planTimer.end(), logs);

  return commissions;
};

export const calculateCommissionForUser = async ({
  dateRange,
  company,
  user,
  isProjected,
  logs,
}: {
  dateRange: DateRange;
  company: NestedCompany;
  user: NestedUser;
  isProjected: boolean;
  logs: string[];
}) => {
  // Get overall provider stats for all deals
  const allDeals = company.deals || [];
  const providerStats = getProviderStats(allDeals);

  const userTimer = new OperationTimer('calculateCommissionForUser', { 
    userId: user.id, 
    email: user.email,
    numPlans: user.plans?.length || 0,
    totalDeals: allDeals.length,
    providerStats
  });

  const allCommissions: NestedCommission[] = [];
  const { plans } = user;
  if (!plans || (plans && plans.length === 0)) {
    logOperation(userTimer.end(), logs);
    return allCommissions;
  }

  const { from, to } = dateRange || {};
  if (!from || !to) {
    logOperation(userTimer.end(), logs);
    return allCommissions;
  }

  const calculationDate = new Date(from);
  while (calculationDate <= new Date(to)) {
    // Get deals for this period
    const periodDeals = allDeals.filter(deal => {
      const dealDate = new Date(deal.closed_at || deal.created_at);
      return dealDate.getMonth() === calculationDate.getMonth() &&
             dealDate.getFullYear() === calculationDate.getFullYear();
    });
    
    const periodProviderStats = getProviderStats(periodDeals);

    const periodTimer = new OperationTimer('period-processing', {
      userId: user.id,
      period: `${format(calculationDate, "yyyy-MM")}`,
      numDeals: periodDeals.length,
      providerStats: periodProviderStats
    });

    logs.push(
      `-- ${Number(format(calculationDate, "yyyy"))}-${Number(
        format(calculationDate, "MM")
      )}: ${user.email} --\n`
    );

    await Promise.all(plans.map(async (plan) => {
      const commissions = await calculateCommissionsForPlan({
        calculationDate,
        dateRange,
        company,
        user,
        plan,
        isProjected,
        logs,
      });
      if (commissions && commissions.length > 0)
        allCommissions.push(...commissions);
    }));

    logOperation(periodTimer.end(), logs);
    calculationDate.setMonth(calculationDate.getMonth() + 1);
  }

  if (logs)
    logs.push(
      `Generated ${allCommissions.length} commissions for user ${user.email} between ${format(
        from,
        "yyyy-MM-dd"
      )} and ${format(to, "yyyy-MM-dd")}.`
    );

  logOperation(userTimer.end(), logs);
  return allCommissions;
};

export const getCommissionsValue = (commissions: NestedCommission[]) => {
  if (!commissions || commissions?.length === 0) return 0;
  return commissions?.reduce((acc, commission) => acc + commission?.amount, 0);
};

export const getCommissionForDateRange = (
  commissions: NestedCommission[],
  dateRange?: DateRange,
  filterOnStatuses?: CommissionStatus[]
) => {
  const { from, to } = dateRange || {};
  if (!from || !to) return [];
  
  // Pre-compute date range boundaries once
  const startDate = startOfDay(from);
  const endDate = endOfDay(to);
  
  // Create status set for O(1) lookup
  const statusSet = filterOnStatuses ? new Set(filterOnStatuses) : null;
  
  // Use a more efficient filter
  return commissions?.filter((commission) => {
    // Early return for status check
    if (statusSet && commission.status && !statusSet.has(commission.status)) {
      return false;
    }
    
    // Cache the commission date
    const commissionDate = new Date(commission.year, commission.month - 1);
    return commissionDate >= startDate && commissionDate <= endDate;
  });
};

export const getTotalCommissionOfUsers = (users: CommissionUser[]) => {
  return users.reduce((acc, user) => acc + user.value, 0);
};

export const getMonthName = (commission: NestedCommission) => {
  const { year, month } = commission;
  // Get name of month from number
  const date = new Date(year, month - 1);
  return format(date, "MMMM", { locale: enUS });
};

export const getUsersForScope = (
  company: SignedInCompany,
  scope?: Scope | null
) => {
  const { users, teams } = company || {};
  // Get users for scope
  if (!scope) return users;
  if (scope.type === "user") {
    return users.filter(
      (user) => user.id === scope.value
    );
  }
  // TODO: Add team scope by filtering on users within a team
  if (scope.type === "team") {
    const userIds = getUserIdsForTeam(teams, Number(scope.value));
    return users.filter((user) => userIds.includes(user.id));
  }
  return users;
};

export const getPaidCommissions = (commissions: NestedCommission[]) => {
  return commissions.filter((commission) => commission.status === "paid");
};

export const getKeyDeals = (
  scopedUsers: NestedUser[],
  groupedCommissions: GroupedCommissions,
  limit: number = 3
): KeyDeal[] => {
  // Create user map for O(1) lookup
  const userMap = new Map(scopedUsers?.map(user => [user.id, user]));
  
  // Pre-allocate array with estimated size
  const keyDeals: KeyDeal[] = [];
  
  // Process entries without intermediate array creation
  for (const [dealName, commissions] of Object.entries(groupedCommissions)) {
    const firstCommission = commissions?.[0];
    if (!firstCommission) continue;
    
    const deal = firstCommission.deal;
    if (!deal || deal.is_won || deal.is_lost) continue;
    
    const owner = userMap.get(firstCommission.user_id);
    if (!owner) continue;
    
    const dealValue = getDealValueFromCommissions(commissions);
    const commissionValue = getCommissionsValue(commissions);
    
    keyDeals.push({
      dealName,
      dealValue,
      owner,
      commissionValue,
    });
    
    // Early exit if we have enough deals
    if (keyDeals.length >= limit * 2) {
      break;
    }
  }
  
  // Sort only the collected deals and take top N
  return keyDeals
    .sort((a, b) => b.dealValue - a.dealValue)
    .slice(0, limit);
};

export const getCommissionsByQuotaTargetKeyDeals = (
  commissions: NestedCommission[],
  limit: number = 3
): NestedCommission[] => {
  return commissions
    .filter((c) => !c.deal?.is_won)
    .toSorted((a, b) => b.amount - a.amount)
    .slice(0, limit);
};

export const consolidateCommissions = (
  scopedCommissions: NestedCommission[],
  persistedCommissions?: NestedCommission[]
) => {
  if (!persistedCommissions?.length) return scopedCommissions;
  
  // Create an efficient lookup map while preserving exact matching logic
  const persistedMap = new Map<string, NestedCommission>();
  
  // Use the exact same matching fields as before
  for (const commission of persistedCommissions) {
    // Maintain the same field order and matching criteria
    const key = [
      commission.year,
      commission.month,
      commission.deal_id,
      commission.accelerator_id,
      commission.user_id
    ].join('|');
    persistedMap.set(key, commission);
  }
  
  // Use the same matching logic but with Map lookup instead of find
  return scopedCommissions.map(commission => {
    const key = [
      commission.year,
      commission.month,
      commission.deal_id,
      commission.accelerator_id,
      commission.user_id
    ].join('|');
    
    // Return persisted commission if found, otherwise return original
    return persistedMap.get(key) || commission;
  });
};
