import {
  Scope,
  DateRange,
  // NestedCommission,
  SignedInUser,
  // TODO,
} from "@revelate/types";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import type {} from "@redux-devtools/extension"; // required for devtools typing
import { getUser } from "@/lib/supabase";
import { defaultDateRange, defaultDateRangeValue } from "@/constants/dateRange";
import { parseISO, format } from "date-fns";
import { OnChangeFn, VisibilityState } from "@tanstack/react-table";
import { Session } from "@supabase/supabase-js";
import { getUserRoleFromSession } from "@/lib/app/user_roles";

interface AppState {
  scope: Scope | null;
  setScope: (scope: Scope | null) => void;
  // currentPlan: NestedPlan | null;
  // currentAccelerator:
  //   | Database["public"]["Tables"]["accelerators"]["Row"]
  //   | null;
  // scopedUsers: NestedUser[];
  // allUsers: NestedUser[];
  // scopedDeals: Database["public"]["Tables"]["deals"]["Row"][];
  // scopedCommissions: NestedCommission[];
  // list: ListItem[];
  columnVisibility: VisibilityState;
  setColumnVisibility: OnChangeFn<VisibilityState>;
  dateRangeValue: string;
  setDateRangeValue: (dateValue: string) => void;
  dateRange: DateRange;
  setDateRange: (dateRange?: DateRange) => void;
  currentUser: SignedInUser | null;
  // calculate: () => void;
  fetch: (session: Session | null) => Promise<SignedInUser | null>;
  reset: () => void;
  isLoaded: () => boolean;
}

const defaultColumnVisibility = {
  provider: false,
  provider_id: false,
  is_closed: false,
  is_invoiced: false,
  is_paid: false,
  days_to_close: false,
  created_at: false,
};

const initialState: AppState = {
  scope: null,
  setScope: () => {},
  // currentPlan: null,
  // currentAccelerator: null,
  // scopedUsers: [],
  // allUsers: [],
  // scopedDeals: [],
  // scopedCommissions: [],
  // list: [],
  columnVisibility: defaultColumnVisibility,
  setColumnVisibility: () => defaultColumnVisibility,
  dateRangeValue: defaultDateRangeValue,
  setDateRangeValue: () => {},
  dateRange: defaultDateRange,
  setDateRange: () => {},
  currentUser: null,
  // company: null,
  // calculate: () => {},
  fetch: async () => null,
  reset: () => {},
  isLoaded: () => false,
};

const updateURLParams = (dateRange?: DateRange) => {
  const url = new URL(window.location.href);
  if (dateRange?.from) {
    url.searchParams.set("from", format(dateRange.from, "yyyy-MM-dd"));
  } else {
    url.searchParams.delete("from");
  }
  if (dateRange?.to) {
    url.searchParams.set("to", format(dateRange.to, "yyyy-MM-dd"));
  } else {
    url.searchParams.delete("to");
  }
  window.history.replaceState({}, "", url.toString());
};

const getDateRangeFromURL = (): DateRange => {
  const url = new URL(window.location.href);
  const from = url.searchParams.get("from");
  const to = url.searchParams.get("to");
  return {
    from: from ? parseISO(from) : undefined,
    to: to ? parseISO(to) : undefined,
  };
};

export const useAppStore = create<AppState>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setScope: (scope) => {
          // const { company } = get();
          // const company = null;
          // TODO: get company from session
          // const plan = getPlanFromScope(scope, company);
          set({ scope });
          // const { calculate } = get();
          // calculate();
        },
        setColumnVisibility: (columnVisibility) => {
          // console.log("setColumnVisibility", columnVisibility);
          set((state) => ({
            columnVisibility: {
              ...state.columnVisibility,
              ...columnVisibility,
            },
          }));
        },
        setDateRangeValue: (dateRangeValue) => {
          const fromStr = dateRangeValue?.split(",")[0] || undefined;
          const toStr = dateRangeValue?.split(",")[1] || undefined;
          const dateRange = {
            from: fromStr ? parseISO(fromStr) : undefined,
            to: toStr ? parseISO(toStr) : undefined,
          };
          updateURLParams(dateRange);
          set({
            dateRangeValue,
            dateRange,
          });
        },
        setDateRange: (dateRange) => {
          updateURLParams(dateRange);
          set({ dateRange, dateRangeValue: "" });
        },
        fetch: async (session) => {
          const { user: authUser } = session || {};
          // TODO: Move user state handling to SWR
          // TODO: Make sure cached user data (e.g. role) can not be changed
          const authRole = getUserRoleFromSession(session);
          const publicUser = authUser ? await getUser(authUser.id) : null;
          // This attaches the auth role to the user and requires another login if role changes
          // Remove to allow instant role changes
          const currentUser = publicUser
            ? {
                ...publicUser,
                role: authRole || publicUser.role,
              }
            : null;

          set({
            currentUser,
          });
          return currentUser;
        },
        isLoaded: () => {
          const { currentUser } = get();
          return !!currentUser;
        },
        reset: () => set(initialState),
      }),
      {
        name: "revelate-app-storage",
        // getStoredState: () => {
        //   const storedState = localStorage.getItem("revelate-app-storage");
        //   if (storedState) {
        //     const dateRange = getDateRangeFromURL();
        //     return { ...JSON.parse(storedState), dateRange };
        //   }
        //   return undefined;
        // },
      }
    )
  )
);
