import {
  BaseCompany,
  Database,
  NestedDeal,
  NestedExchangeRate,
} from "@revelate/types";
import { isAfter } from "date-fns";

const isValid = (exchangeRate: NestedExchangeRate) => {
  return (
    exchangeRate.valid_to === null ||
    isAfter(new Date(exchangeRate.valid_to), new Date())
  );
};

export const getExchangeRateToCurrency = (
  currency: Database["public"]["Tables"]["currencies"]["Row"],
  company?: BaseCompany | null
) => {
  const { code: toCode, id: toId } = currency || {};
  const { default_currency } = company || {};
  const { code, exchange_rates } = default_currency || {};
  if (code === toCode) return 1;
  const exchangeRate = exchange_rates?.find(
    (exchangeRate) =>
      exchangeRate.from_currency_id === toId && isValid(exchangeRate)
  );
  if (!exchangeRate) {
    return 0;
  }
  return exchangeRate.rate;
};

export const getExchangeRateForDeal = (
  company: BaseCompany | null,
  deal: NestedDeal,
  logs?: string[]
) => {
  const { currency_id, currency_code } = deal;
  const { default_currency } = company || {};
  const { code, exchange_rates } = default_currency || {};
  if (default_currency?.code === currency_code) return 1;
  const exchangeRate = exchange_rates?.find(
    (exchangeRate) =>
      exchangeRate.from_currency_id === currency_id && isValid(exchangeRate)
  );
  if (!exchangeRate) {
    if (logs) {
      logs.push(
        `ERROR: No exchange rate found for deal ${deal.id} (${deal.currency_code} to ${code} missing)`
      );
    }
    return 0;
  }
  return exchangeRate.rate;
};

export const getExchangeRateForCurrencyId = (
  company: BaseCompany | null,
  currencyId: number
) => {
  const { default_currency, default_currency_id } = company || {};
  const { exchange_rates } = default_currency || {};
  if (default_currency_id === currencyId) return 1;
  const exchangeRate = exchange_rates?.find(
    (exchangeRate) =>
      exchangeRate.from_currency_id === currencyId && isValid(exchangeRate)
  );
  if (!exchangeRate) {
    console.error(`No exchange rate found for currency id ${currencyId}`);
    return 0;
  }
  return exchangeRate.rate;
};
