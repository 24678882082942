import { createContext, useContext, ReactNode } from "react";
import { useCommissionsStream } from "@/hooks/useCommissionsStream";
import { useAppStore } from "@/stores";
import { NestedUser } from "@revelate/types";

interface CommissionsContextType {
  users: NestedUser[] | undefined;
  projectedUsers: NestedUser[] | undefined;
  error: string | null;
  isLoading: boolean;
}

const CommissionsContext = createContext<CommissionsContextType | undefined>(undefined);

export function CommissionsProvider({ children }: { children: ReactNode }) {
  const { currentUser, dateRange } = useAppStore((state) => state);

  const {
    data: users,
    error: usersError,
    isLoading: usersLoading,
  } = useCommissionsStream({
    currentUser,
    dateRange,
  });

  const {
    data: projectedUsers,
    error: projectedUsersError,
    isLoading: projectedUsersLoading,
  } = useCommissionsStream({
    currentUser,
    dateRange,
    isProjected: true,
  });

  return (
    <CommissionsContext.Provider
      value={{
        users,
        projectedUsers,
        error: usersError || projectedUsersError,
        isLoading: usersLoading || projectedUsersLoading,
      }}
    >
      {children}
    </CommissionsContext.Provider>
  );
}

export function useCommissions() {
  const context = useContext(CommissionsContext);
  if (context === undefined) {
    throw new Error("useCommissions must be used within a CommissionsProvider");
  }
  return context;
}
